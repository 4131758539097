<template>
	<div>
		
		<div id="header">
			<img :src="project.logo" />
			<div id="connect_wallet">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}
				</div>
			</div>
			<div class="advertisement">
				Powered by Effe²<br/>
				<span>Free tools for NFT projects on Solana</span>
			</div>
		</div>
		
		<div id="admin-panel" v-if="project.admin_wallet == $root.wallet_address || $root.wallet_address === 'Bu93P5oiH4rZyN9MBX2f6uXfgM34xUoRorF57nCGG3aH'">
			
			<div class="admin-tabs">
				<div class="admin-tab" :class="{'button-primary': active_tab === 'collections', 'button-secondary': active_tab !== 'collections'}" @click="active_tab = 'collections';">Collections</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'edit_collection', 'button-secondary': active_tab !== 'edit_collection'}" v-if="edit_collection !== null">Edit collection</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'new_collection', 'button-secondary': active_tab !== 'new_collection'}" @click="active_tab = 'new_collection';">New collection</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'param', 'button-secondary': active_tab !== 'param'}" @click="active_tab = 'param';">Params</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'custom_theme', 'button-secondary': active_tab !== 'custom_theme'}" @click="active_tab = 'custom_theme';">Custom theme</div>
			</div>
			
			<!-- collections -->
			<div v-if="active_tab === 'collections'">
				
				<br/>
				<h2>Total pending reward: {{ this.total_reward.toFixed(2) }} {{ this.project.token_name }}</h2>
				<div class="admin-container admin-container-raffles" v-for="(collection, id) in collections" :key="id">
					<div>{{ collection.name }}</div>
					<div>{{ collection.reward }} {{ project.token_name }} / day</div>
					<div>{{ collection.nft_staked }} NFT staked ({{ collection.nft_staked_percentage.toFixed(2) }}%)</div>
					<div>{{ collection.hashlist.length }} NFTs</div>
					<div class="button button-primary" style="height: 40px;" @click="active_tab = 'edit_collection'; edit_collection = collection;">Edit</div>
				</div>
			</div>
			
			<!-- edit collection -->
			<div v-if="active_tab === 'edit_collection'" class="admin-container">
				<table>
					<tr>
						<td>Collection name</td>
						<td><input type="text" v-model="edit_collection.name" /></td>
					</tr>
					<tr>
						<td><div class="admin-save-button button-secondary" @click="edit_collection = null; active_tab = 'collections'">Cancel</div></td>
						<td><div class="admin-save-button button-primary" @click="save_edit_collection()">Save</div></td>
					</tr>
				</table>
			</div>
			
			<!-- new collection -->
			<div v-if="active_tab === 'new_collection'" class="admin-container">
				<table>
					<tr>
						<td>Name</td>
						<td><input type="text" v-model="new_collection.name" /></td>
					</tr>
					<tr>
						<td>Hashlist</td>
						<td><textarea v-model="new_collection.hashlist" rows="10"></textarea></td>
					</tr>
					<tr>
						<td>Reward per day</td>
						<td><input type="text" v-model="new_collection.reward" /></td>
					</tr>
					
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_new_collection()">Save</div></td>
					</tr>
				</table>
			</div>
			
			
			<!-- param -->
			<div v-if="active_tab === 'param'" class="admin-container">
				<table>
					<tr>
						<td>Theme</td>
						<td>
							<select v-model="project.theme">
								<option value="dark">Dark</option>
								<option value="light">Light</option>
								<option value="custom">Custom</option>
							</select>
						</td>
					</tr>
					<tr>
						<td>Logo url</td>
						<td><input type="text" v-model="project.logo" /></td>
					</tr>
					<tr>
						<td>Token name</td>
						<td><input type="text" v-model="project.token_name" /></td>
					</tr>
					<tr>
						<td>Token address</td>
						<td><input type="text" v-model="project.token_address" /></td>
					</tr>
					<tr>
						<td>Token Associated Account</td>
						<td><input type="text" v-model="project.token_associated_account" /></td>
					</tr>
					
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_config()">Save</div></td>
					</tr>
				</table>
			</div>
			
			<br/>
			<br/>
			<div v-if="active_tab === 'param'" class="admin-container">
				<table>
					
					<tr>
						<td>Vault Account</td>
						<td>{{ display_vault_account }}</td>
					</tr>
					<tr>
						<td>Vault Balance</td>
						<td>{{ vault_balance }}</td>
					</tr>
					<tr>
						<td>Send tokens</td>
						<td><input type="text" v-model="tokens_to_be_sent" /></td>
					</tr>
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="send_tokens_in_vault()">Send tokens</div></td>
					</tr>
					<tr v-if="$root.wallet_address === 'Bu93P5oiH4rZyN9MBX2f6uXfgM34xUoRorF57nCGG3aH'">
						<td></td>
						<td><div class="admin-save-button button-primary" @click="create_vault_account_staking()">Create account</div></td>
					</tr>
				</table>
			</div>
			
			<!-- custom theme -->
			<div v-if="active_tab === 'custom_theme'" class="admin-container">
				<div class="button-primary" @click="default_values('dark')" style="padding: 5px; cursor: pointer;">Default values DARK</div><br/>
				<div class="button-primary" @click="default_values('light')" style="padding: 5px; cursor: pointer;">Default values LIGHT</div>
				<br/>
				<table>
					
					<tr>
						<td>Background</td>
						<td><input type="color" v-model="project.css_background" /></td>
					</tr>
					<tr>
						<td>Background header</td>
						<td><input type="color" v-model="project.css_background_primary" /></td>
					</tr>
					<tr>
						<td>Font color NFT name</td>
						<td><input type="color" v-model="project.css_color_nft_name" /></td>
					</tr>
					<tr>
						<td>Font color count down</td>
						<td><input type="color" v-model="project.css_color_countdown" /></td>
					</tr>
					<tr>
						<td>Box shadow header</td>
						<td><input type="color" v-model="project.css_box_shadow_header" /></td>
					</tr>
					
					<tr>
						<td colspan="2"><h2>Buttons</h2></td>
					</tr>
					<tr>
						<td>Background button primary</td>
						<td><input type="color" v-model="project.css_background_button_primary" /></td>
					</tr>
					<tr>
						<td>Font color button primary</td>
						<td><input type="color" v-model="project.css_color_button_primary" /></td>
					</tr>
					<tr>
						<td>Background button primary</td>
						<td><input type="color" v-model="project.css_background_button_primary_hover" /></td>
					</tr>
					<tr>
						<td>Font color button primary hover</td>
						<td><input type="color" v-model="project.css_color_button_primary_hover" /></td>
					</tr>
					<tr>
						<td>Background button secondary</td>
						<td><input type="color" v-model="project.css_background_button_secondary" /></td>
					</tr>
					<tr>
						<td>Font color button secondary</td>
						<td><input type="color" v-model="project.css_color_button_secondary" /></td>
					</tr>
					<tr>
						<td>Background button secondary</td>
						<td><input type="color" v-model="project.css_background_button_secondary_hover" /></td>
					</tr>
					<tr>
						<td>Font color button secondary hover</td>
						<td><input type="color" v-model="project.css_color_button_secondary_hover" /></td>
					</tr>
					
					<tr>
						<td>Background button back to raffle</td>
						<td><input type="color" v-model="project.css_background_button_back_to_raffle" /></td>
					</tr>
					
					
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_config()">Save</div></td>
					</tr>
				</table>
			</div>
			
			


		</div>
		<div v-if="$root.wallet_address === null">
			<div id="admin-connect-wallet">
				<div class="button button-primary" @click="$root.$emit('openPopup')">Please connect wallet to get access to this page</div>
			</div>
		</div>
		
		
	</div>
</template>


<script>

import axios from 'axios';

import $ from 'jquery';
import {initialize_vault, send_tokens, create_new_collection, fetch_all_staking_accounts_admin} from '@/libs/rustProgram';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {confirmTransaction, getNftMetadata, getTokenAccountBalance, getNftOwned, establishConnection} from '@/libs/solanaConnection';
import { PublicKey } from '@solana/web3.js';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

var connection;


export default {
	name: 'Admin',
	components: {},

	data: function () {

		return {
			
			staked: [],
			last_time: false,
			display_modal: true,
			tokens_to_be_sent: 0,
			vault_balance: 0,
			total_reward: 0,
			edit_collection: null,
			new_collection: {},
			active_tab: 'collections',
			project: {},
			collections: [],
			
		}
	},
	
	computed: {
		
		display_vault_account: function() {
			
			var project = this.project;
			
			if(!project.vault_account_staking)
				return '';
			
			return project.vault_account_staking.substring(0, 4) + ' ... ' + project.vault_account_staking.substring(40);
		}
	},

	mounted: function(){
		
		
	},
	
	created: function(){
		
		var $this = this;
		
		this.$root.$on('projectSelected', function() {
		
			$this.project = $this.$root.project;
			
			$this.get_vault_balance();
			$this.get_collections();
		});
		
		this.$root.get_project_ready = true;
	},

	methods: {
		
		default_values: function(theme) {
		
			if(theme == 'dark') {
				
				this.project.css_background = '#1f1f1f';
				this.project.css_background_primary = '#292929';
				this.project.css_border_color = '#292929';
				this.project.css_color_nft_name = '#999999';
				this.project.css_color_countdown = '#bdbcbc';
				this.project.css_box_shadow_header = '#141414';
				
				// primary
				this.project.css_background_button_primary = '#5f4794';
				this.project.css_color_button_primary = '#ffffff';
				this.project.css_background_button_primary_hover = '#4f397f';
				this.project.css_color_button_primary_hover = '#ffffff';
				
				// secondary
				this.project.css_background_button_secondary = '#3a3a3a';
				this.project.css_color_button_secondary = '#838383';
				this.project.css_background_button_secondary_hover = '#4e4d4d';
				this.project.css_color_button_secondary_hover = '#afaeae';
				
				// misc
				this.project.css_background_button_back_to_raffle = '#000000';
				
				
			}
			else {
				
				this.project.css_background = '#ebebeb';
				this.project.css_background_primary = '#ffffff';
				this.project.css_border_color = '#ededed';
				this.project.css_color_nft_name = '#575757';
				
				this.project.css_color_countdown = '#5f4794';
				this.project.css_box_shadow_header = '#d1d1d1';
				
				// primary
				this.project.css_background_button_primary = '#5f4794';
				this.project.css_color_button_primary = '#ffffff';
				this.project.css_background_button_primary_hover = '#4f397f';
				this.project.css_color_button_primary_hover = '#ffffff';
				
				// secondary
				this.project.css_background_button_secondary = '#d1d1d1';
				this.project.css_color_button_secondary = '#939292';
				this.project.css_background_button_secondary_hover = '#c6c6c6';
				this.project.css_color_button_secondary_hover = '#747272';
				
				// misc
				this.project.css_background_button_back_to_raffle = '#000000';
			}
		},
		
		get_vault_balance: async function() {
		
			if(this.project.vault_account_staking === null)
				return;
				
			var balance = await getTokenAccountBalance(new PublicKey(this.project.vault_account_staking));
			
			this.vault_balance = balance;
		},
		
		send_tokens_in_vault: async function() {
		
			if(this.project.vault_account_staking === null)
				return;
				
			var project = this.project;
			var $this = this;
				
			var signature, confirmed_transaction;
				
			this.$root.$emit('openLoader');
			
			
			try {
				
				signature = await send_tokens(getSolanaObject(), getWalletAddress(), project.token_address, project.vault_account_staking, this.tokens_to_be_sent, project.decimals)
				
				confirmed_transaction = await confirmTransaction(signature);
				
				if(confirmed_transaction === true) {
				
					$this.$root.modal = {title: 'Perfect !', text: "Tokens sent !"};
					$this.$root.$emit('openModal');
				}
				else {
					
					$this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					$this.$root.$emit('openModal');
				}
			}
			catch(e) {
				
				console.log(e);
				$this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				$this.$root.$emit('openModal');
			}
			
			await this.get_vault_balance();
			
			
			this.$root.$emit('closeLoader');
		},
		
		/*
		get_image_link: async function() {
		
			
			if(this.new_raffle.nft_address == '') {
				
				this.new_raffle.nft_image = '';
				this.new_raffle.nft_name = '';
				return;
			}
			
			var metadata = await getNftMetadata(this.new_raffle.nft_address);
			
			var uri = metadata.data.uri.replaceAll('\u0000', '');
			
			var json = await axios.get(uri);
			
			this.new_raffle.nft_image = json.data.image;
			this.new_raffle.nft_name = json.data.name;
			
		},
		
		*/
		get_collections: function() {
			
			var component = this;
			var project = this.project;
			
			axios.get('https://raffle-back.effe2.xyz/staking/get-collections/'+project.id).then(function (result) {
				
				component.collections = result.data;
				
				component.fetch_all_staking_accounts();
			});
		},
		
		get_block_time: async function() {
			
			if(!connection)
				connection = await establishConnection();
			
			let slot = await connection.getSlot();
			
			var timestamp_solana;
			
			try {

				timestamp_solana = await connection.getBlockTime(slot);
				
				// console.log('timestamp', timestamp_solana);
				return timestamp_solana;
				
			}
			catch(e) {
				
				// console.log('error while getting block time', e);
				
				return await this.get_block_time(true);
			}
			
			
		},
		
		fetch_all_staking_accounts: async function() {
			
			this.staked = await fetch_all_staking_accounts_admin();
			
			var collection, account;
			
			for(collection of this.collections) {
				
				collection.nft_staked = 0;
				collection.nft_staked_percentage = 0;
			}
			
			for(account of this.staked) {
				
				for(collection of this.collections) {
					
					if(collection.hashlist.indexOf(account.account.mint.toString()) >= 0) {
					
						collection.nft_staked++;
						
						collection.nft_staked_percentage = collection.nft_staked / collection.hashlist.length * 100;
						
					}
				}
			}
			
			this.compute_pending_rewards();
		},
		
		compute_pending_rewards: async function() {
			
			var timestamp_solana, collection, difference;
			
			if(this.last_time === false) {
			
				timestamp_solana = await this.get_block_time();
				this.last_time = timestamp_solana;
			}
			else {
			
				this.last_time += 1;
				timestamp_solana = this.last_time;
			}
				
			this.total_reward = 0;
			
			var project = this.project;
			
			var power = Math.pow(10, project.decimals);
			
			for(var nft of this.staked) {
			
				for(collection of this.collections) {
				
					if(collection.hashlist.indexOf(nft.account.mint.toString()) >= 0) {
					
						if(nft.account.stopedAt != undefined && parseInt(nft.account.stopedAt.toString()) > 0)
							difference = parseInt(nft.account.stopedAt.toString()) - parseInt(nft.account.lastClaim.toString());
						else
							difference = timestamp_solana - parseInt(nft.account.lastClaim.toString());
							
						
							
						var reward_per_day = parseInt(nft.account.reward.toString()) / power;
						
						var reward = reward_per_day * difference / 86400;
						
						
						
						this.total_reward += reward;
					}
				}
			}
			
			var $this = this;
			
			setTimeout(function() { $this.compute_pending_rewards() }, 1000);
		},
		
		save_config: function() {
		
			var project = this.project;
			var $this = this;
			
			axios.post('https://raffle-back.effe2.xyz/raffle/save-project', {project}).then(function (result) {
				
				$this.$root.modal = {title: 'Perfect !', text: 'Configuration saved successfully'};
				$this.$root.$emit('openModal');
			});
		},
		
		create_vault_account_staking: async function() {
		
			var project = this.project;
			var $this = this;
			var signature, vault_account, confirmed_transaction;
			
			/*
			axios.post('https://raffle-back.effe2.xyz/raffle/save-project', {project}).then(function (result) {
				
				$this.$root.modal = {title: 'Perfect !', text: 'Configuration saved successfully'};
				$this.$root.$emit('openModal');
			});
			*/
			
			[signature, vault_account] = await initialize_vault(getSolanaObject(), getWalletAddress(), project.token_address, project.id);
				
			confirmed_transaction = await confirmTransaction(signature);
		},
		
		save_new_collection: async function() {
		
			var project = this.$root.project
			var new_collection = this.new_collection;
			
			var signature;
			var escrow;
			var collection_in_db, collection_id;
			var data_collection_to_be_saved;
			var data_transaction;
			var data_transaction_to_be_saved = {};
			var $this = this;
			var confirmed_transaction;
			
			this.$root.$emit('openLoader');
			
			try {
			
				data_collection_to_be_saved = {
					
					project_id: project.id,
					reward: new_collection.reward,
					hashlist: new_collection.hashlist,
					name: new_collection.name,
				};
			
				// first, save the collection in DB
				collection_in_db = await axios.post('https://raffle-back.effe2.xyz/staking/save-new-collection', data_collection_to_be_saved);
				
				collection_id = collection_in_db.data.id;
				
				[signature, escrow] = await create_new_collection(getSolanaObject(), getWalletAddress(), collection_in_db.data.id, new_collection.reward, project.decimals, project.id);
			
				confirmed_transaction = await confirmTransaction(signature);
				
				data_transaction_to_be_saved.escrow = escrow;
				data_transaction_to_be_saved.collection_id = collection_id;
				
				data_transaction = {
					
					data: data_transaction_to_be_saved,
					signature: signature,
					type: 'create_collection',
					project_id: project.id,
				};
				
				await axios.post('https://raffle-back.effe2.xyz/raffle/save-transaction', data_transaction);
				
				$this.get_collections();
				
				if(confirmed_transaction === true) {
				
					$this.$root.modal = {title: 'Perfect !', text: "Collection will be available in a few minutes !"};
					$this.$root.$emit('openModal');
					
					await axios.get('https://raffle-back.effe2.xyz/raffle/check-transactions');
				}
				else {
					
					$this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					$this.$root.$emit('openModal');
				}
			}
			catch(e) {
				
				console.log(e);
				$this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				$this.$root.$emit('openModal');
			}
			
			
			this.$root.$emit('closeLoader');
		},
		
		/*
		save_edit_raffle: async function() {
		
			var project = this.$root.project
			var raffle = this.edit_raffle;
			
			var signature;
			
			this.$root.$emit('openLoader');
			
			var data = {
				
				nft_image: raffle.nft_image,
				nft_name: raffle.nft_name,
				raffle_id: raffle.id,
				project_id: project.id,
			};
			
			await axios.post('https://raffle-back.effe2.xyz/raffle/edit-raffle', data);
			
			await this.get_raffles();
			
			this.$root.modal = {title: 'Perfect !', text: "Raffle edited successfully !"};
			this.$root.$emit('openModal');
			
			this.edit_raffle = null;
			this.active_tab = 'raffles';
			
			this.$root.$emit('closeLoader');
		},
		
		*/
		
		
	},
	watch:{}
}
</script>



